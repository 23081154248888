/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useState } from 'react';
import { Accordion, Button, Modal, Spinner } from 'react-bootstrap';
import { BsDownload, BsFillRecordFill } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import AssessmentComponent from '../_components/assessment.component';
import ModuleChapterComponent from '../_components/moduleChapter.component';
import ScormModuleView from '../_components/scormModuleView.component';
import UserAuthStateContext from '../_contexts/userAuth.context';
import { formatModuleName } from '../_helpers/utils';
import useAPI from '../_hooks/useAPI';
import {
    LearningModuleSummary,
    ModuleChapter,
    ModuleFiles,
    ModuleTypes,
    RequestOptions,
    UserDetails
} from '../_models/types';
import { moduleService, quizService, userService } from '../_services';

const ModuleViewPage = () => {
    const [currentView, setCurrentView] = useState('');
    const { UserAuthState } = useContext(UserAuthStateContext);
    const { category } = useParams<string>();
    const [moduleNumber, setModuleNumber] = useState<number | null>(null);
    const [chapterNumber, setChapterNumber] = useState<number>(1);
    const [loadedChapter, setLoadedChapter] = useState<any>();
    const [moduleProgress, setModuleProgress] = useState<any>(null);
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [moduleChapters, setModuleChapters] = useState<ModuleChapter[]>([]);
    const [moduleFiles] = useState<ModuleFiles>(() => moduleService.moduleAssets.find(x => x.name === (category as string)) as ModuleFiles);
    const [user, setUser] = useState<UserDetails | null>(null);
    const [allModules, setAllModules] = useState<any>(null);
    const [loadingCert, setLoadingCert] = useState(false);
    const [quizRes, setQuizResult] = useState<{ progress: number, result: number }>();
    const [moduleSummary, setModuleSummary] = useState<LearningModuleSummary | null>(null);
    const fetchWrapper = useAPI();
    const [showModal, setShowModal] = useState(false);
    const [showDisclaimer, setShowDisclaimer] = useState(false);

    const handleRegisterClick = () => setShowDisclaimer(true);
    const handleModalOpen = () => setShowModal(true);
    const handleModalClose = () => {
        setShowModal(false);
        setShowDisclaimer(false);
    };

    useEffect(() => {
        const getUser = async () => {
            if (UserAuthState) {
                const userDetails = await userService.getUserInformation(UserAuthState.user_id, fetchWrapper);
                setUser({ ...userDetails });
            }
        };
        getUser();
    }, [UserAuthState]);

    useEffect(() => {
        const getFiles = async () => {
            setLoadedChapter(
                moduleService
                    .modulesDetails(category as string)
                    .getChapter(chapterNumber)
            );
        };
        getFiles();
    }, [chapterNumber]);

    useEffect(() => {
        category && setModuleNumber(ModuleTypes[category as keyof typeof ModuleTypes]);
        setModuleChapters(
            moduleService
                .modulesDetails(category as string)
                .getModuleChapters() ?? []
        );
    }, [category]);

    useEffect(() => {
        const loadProgress = async () => {
            setActiveIndex((await getModuleProgress(chapterNumber)).completed);
        };
        moduleNumber && loadProgress();
    }, [moduleNumber, chapterNumber]);

    useEffect(() => {
        if (moduleProgress) {
            if (moduleProgress.completed) {
                setActiveIndex(moduleProgress.completed);
            }
        }
    }, [moduleProgress]);

    useEffect(() => {
        allModulesStatus();
    }, [moduleChapters, activeIndex]);

    useEffect(() => {

        quizResult();
    }, []);

    useEffect(() => {
        const getModule = async () => {
            if (UserAuthState) {
                const webModuleSummary = await moduleService.getModuleSummary(fetchWrapper);
                setModuleSummary(webModuleSummary.find(mod => mod.name === category) as LearningModuleSummary);
            }
        };
        getModule();
    }, []);

    const allModulesStatus = async () => {
        if (moduleNumber) {
            const chapterRes = await Promise.all(moduleChapters.map(async (chapt) => ({
                chapterProgress: await moduleService.getModuleCompletedStatus(fetchWrapper, moduleNumber, chapt.chapterNumber),
                chapter: chapt
            })));
            setAllModules(chapterRes);
        }
    };

    const getModuleProgress = async (chapterNo: number) => {
        if (moduleNumber && currentView !== 'assessment') {
            const prog = await moduleService.getModuleCompletedStatus(fetchWrapper, moduleNumber, chapterNo);
            setModuleProgress(prog);
            return prog;
        }
    };

    const downloadTxtFile = async (filename: string) => {
        const downloadfile = (await import(`../assets/module_guides/${ filename }`)).default;
        const element = document.createElement('a');
        element.href = downloadfile;
        element.download = filename;
        element.click();
    };

    const allModulesCompleted = (): string => {
        let completedModules;
        if (allModules) {
            completedModules = allModules.map((mod: any) => mod.chapterProgress.completed === mod.chapterProgress.slides).filter(Boolean);
        }
        if (completedModules && completedModules.length !== allModules.length)
            return 'disabled';
        return '';
    };

    const getCertificate = async () => {
        setLoadingCert(true);
        try {
            const requestOptions = {
                method: 'GET',
                headers: { Authorization: `Bearer ${ UserAuthState?.token }` }
            } as RequestOptions;
            const url = `${ quizService.api }quiz/${ quizService.quizMap(category as string) }/get_certificate/`;
            const response = await fetch(url, requestOptions);
            const _blob = await response.blob();
            const blob = new Blob([_blob]);
            const linkSource = window.URL.createObjectURL(blob);
            const downloadLink = document.createElement('a');
            const fileName = `${ formatModuleName(moduleSummary?.name as string) } Certificate.pdf`;
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
            setLoadingCert(false);
        } catch (error) {
            setLoadingCert(false);
        }
    };

    const isAssessmentPassed = () => {
        return quizRes && quizRes.progress === 100 && quizRes.result >= 80;
    };

    const setIndex = (indx: number) => {
        setActiveIndex(indx);
    };

    const quizResult = async () => {
        try {
            const res = await quizService.getQuizStatus(fetchWrapper, category as string);
            setQuizResult(res);
        } catch (err) {
            console.log(err);
        }
    };

    const downloadCombinedPDF = () => {
        const pdfURL = '/project_management/combined_modules/Combined-Modules-Introduction-to-Project-Management.pdf';
        const link = document.createElement('a');
        link.href = pdfURL;
        link.download = 'Combined-Modules-Introduction-to-Project-Management.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return <>
        { moduleSummary ?
            <div className="col-12 module-view">
                <div className="row">
                    <div className="hero-image">
                        {
                            moduleSummary.is_scorm ? <img src="/images/J_FAQ.jpg" alt="" className="w-100 img-fluid"/> :
                                <img src="/images/M_WELCOME_back.jpg" alt="" className="w-100 img-fluid"/>
                        }
                        <div className="h-100 w-100 hero-image__title">
                            <div className="container text-center">
                                <h3>
                                    { category && `${ formatModuleName(category) } Module` }
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-12 module-view__body">
                        <div className="row">
                            {
                                <div className="col-12 col-lg-3">
                                    <div className="col-12 module-view__module_info sticky-lg-top">
                                        <div className="row">
                                            <Accordion defaultActiveKey="0">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>
                                                        <div className="col-10 module-view__module_info__header-text">
                                                            <p>Topics</p>
                                                        </div>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="col-12 module-view__module_info__body">
                                                            <ul className="row module-view__module_info__body-list-container">
                                                                {
                                                                    moduleSummary?.is_scorm ?
                                                                        <li key="0"
                                                                            className="col-12 module-view__module_info__body-list-item"
                                                                            onClick={ () => {
                                                                                setCurrentView('Content');
                                                                                setChapterNumber(1);
                                                                            }
                                                                            }>
                                                                            <div className="row">
                                                                                <div
                                                                                    className="col-2 module-view--selected">
                                                                                    <BsFillRecordFill/>
                                                                                </div>
                                                                                <div
                                                                                    className="col-10 module-view--selected">
                                                                                    <p>{ 'Content' }</p>
                                                                                </div>
                                                                            </div>
                                                                        </li> :
                                                                        moduleChapters && moduleChapters.map((chapter: any, indx: number) =>
                                                                            <li key={ indx }
                                                                                className="col-12 module-view__module_info__body-list-item"
                                                                                onClick={ () => {
                                                                                    setCurrentView(chapter.name);
                                                                                    setChapterNumber(chapter.chapterNumber);
                                                                                }
                                                                                }>
                                                                                <div className="row">
                                                                                    <div
                                                                                        className={ `col-2 ${ chapter.chapterNumber === chapterNumber ? 'module-view--selected' : '' }` }>
                                                                                        <BsFillRecordFill/>
                                                                                    </div>
                                                                                    <div
                                                                                        className={ `col-10 ${ chapter.chapterNumber === chapterNumber ? 'module-view--selected' : '' }` }>
                                                                                        <p>{ chapter.name }</p>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                }

                                                                {
                                                                    category === 'project_management' &&
                                                                    <div>
                                                                        <div className="row">
                                                                            <li className="col-12 module-view__module_info__body-list-item">
                                                                                <Button
                                                                                    variant="primary"
                                                                                    onClick={ downloadCombinedPDF }
                                                                                    className="w-100"
                                                                                >
                                                                                    Download Content
                                                                                </Button>
                                                                            </li>
                                                                        </div>
                                                                    </div>
                                                                }

                                                                {
                                                                    user && !user.is_admin &&
                                                                    <li className={ `col-12 module-view__module_info__body-list-item ${ allModulesCompleted() }` }>
                                                                        <div className="row" onClick={ () => {
                                                                            setCurrentView('assessment');
                                                                            setChapterNumber(0);
                                                                        } }>
                                                                            <div
                                                                                className={ `col-2 ${ currentView === 'assessment' ? 'module-view--selected' : '' }` }>
                                                                                <BsFillRecordFill/>
                                                                            </div>
                                                                            <div
                                                                                className={ `col-10 ${ currentView === 'assessment' ? 'module-view--selected' : '' }` }>
                                                                                <p>Assessment</p>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                }

                                                                {
                                                                    category === 'project_management' &&
                                                                    <li style={ { cursor: !isAssessmentPassed() ? 'not-allowed' : 'pointer' } }
                                                                        className="col-12 module-view__module_info__body-list-item">
                                                                        <Button
                                                                            variant="primary"
                                                                            disabled={ !isAssessmentPassed() }
                                                                            onClick={ handleModalOpen }
                                                                            className="w-100"
                                                                        >
                                                                            UP Registration
                                                                        </Button>
                                                                    </li>
                                                                }
                                                            </ul>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>

                                                {
                                                    !moduleSummary?.is_scorm &&
                                                    <Accordion.Item eventKey="1">
                                                        <Accordion.Header>
                                                            <div
                                                                className="col-10 module-view__module_info__header-text">
                                                                <p>Resources</p>
                                                            </div>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="col-12 myLearning__module__downloads">
                                                                {
                                                                    user && user.is_admin &&
                                                                    <div className="col-12 download-item"
                                                                         onClick={ () => downloadTxtFile(moduleFiles.moduleGuide) }>
                                                                        <div className="row">
                                                                            <div className="col-3 download-item__icon">
                                                                                <BsDownload/>
                                                                            </div>
                                                                            <div className="col-9 download-item__text">
                                                                                <p>Facilitator Guide</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    moduleFiles.files.map((file, indx) =>
                                                                        <div key={ indx }
                                                                             className="col-12 download-item"
                                                                             onClick={ () => downloadTxtFile(file) }>
                                                                            <div className="row">
                                                                                <div
                                                                                    className="col-3 download-item__icon">
                                                                                    <BsDownload/>
                                                                                </div>
                                                                                <div
                                                                                    className="col-9 download-item__text">
                                                                                    <p>Topic { indx + 1 }</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ) }
                                                                {
                                                                    user && user.is_admin &&
                                                                    <div className="col-12 download-item"
                                                                         onClick={ () => downloadTxtFile(moduleFiles.assessment) }>
                                                                        <div className="row">
                                                                            <div className="col-3 download-item__icon">
                                                                                <BsDownload/>
                                                                            </div>
                                                                            <div className="col-9 download-item__text">
                                                                                <p>Assessment</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    user && user.is_admin &&
                                                                    <div className="col-12 download-item"
                                                                         onClick={ () => downloadTxtFile(moduleFiles.answer) }>
                                                                        <div className="row">
                                                                            <div className="col-3 download-item__icon">
                                                                                <BsDownload/>
                                                                            </div>
                                                                            <div className="col-9 download-item__text">
                                                                                <p>Answers</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                }

                                                {
                                                    user && !user.is_admin &&
                                                    <Accordion.Item eventKey="2">
                                                        <Accordion.Header>
                                                            <div
                                                                className="col-10 module-view__module_info__header-text">
                                                                <p>Certification</p>
                                                            </div>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="col-12 myLearning__module__downloads">
                                                                {

                                                                    <div
                                                                        className={ `col-12 download-item ${ isAssessmentPassed() && !loadingCert ? 'download-item--active' : 'download-item--inactive' }` }>
                                                                        <div className="row" onClick={ getCertificate }>
                                                                            <div className="col-3 download-item__icon">
                                                                                {
                                                                                    loadingCert ?
                                                                                        <Spinner animation="border"
                                                                                                 size="sm"/> :
                                                                                        <BsDownload/> }
                                                                            </div>

                                                                            <div className="col-9 download-item__text">
                                                                                <p>Download Certificate</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                }
                                            </Accordion>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className={ 'col-12 col-lg-9' }>
                                <div className="row">
                                    {
                                        currentView === 'assessment' ?
                                            <AssessmentComponent
                                                moduleNumber={ moduleNumber as number }
                                                moduleName={ category as string }
                                                quizResult={ quizResult }/> :
                                            moduleSummary.is_scorm ?
                                                <ScormModuleView moduleSummary={ moduleSummary }/> :
                                                loadedChapter && <ModuleChapterComponent
                                                    category={ category as string }
                                                    fetchWrapper={ fetchWrapper }
                                                    loadedChapter={ loadedChapter }
                                                    chapterNumber={ chapterNumber }
                                                    moduleNumber={ moduleNumber as number }
                                                    getModuleProgress={ getModuleProgress }
                                                    moduleProgress={ moduleProgress }
                                                    activeIndex={ activeIndex }
                                                    allModules={ allModules }
                                                    setIndex={ setIndex }
                                                    allModulesStatus={ allModulesStatus }
                                                />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : 'Loading....'
        }

        {
            category === 'project_management' &&
            <Modal show={ showModal } onHide={ handleModalClose } centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>UP Registration</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>You need to do an assignment:</p>
                    <div
                        className="disclaimer-box mt-3 p-3 border rounded"
                        style={ {
                            fontSize: '12px',
                            backgroundColor: '#f8f9fa',
                        } }
                    >
                        <p>
                            As an Absa ReadytoWork user, you can apply for an Enterprises University of
                            Pretoria (Enterprises UP) certification of the Introduction to Project
                            Management Course. To qualify for this certification, you would be required
                            to complete an assignment that will be marked by the lecturer of the said
                            course. If you pass this assignment, you will be issued a Certificate of
                            Completion by Enterprises UP.
                            To start your Enterprises UP certification process, you would be required to
                            Register by providing personal information required by Enterprises UP via
                            the Google Form link provided. In addition to the completed Google Form,
                            you would be required to upload your RtW Introduction to Project
                            Management Certificate (it states your name and surname which
                            Enterprises UP will use as verifying you as a RtW user) as well as your
                            completed assignment
                        </p>
                    </div>

                    { showDisclaimer && (
                        <div
                            className="disclaimer-box mt-3 p-3 border rounded"
                            style={ {
                                fontSize: '12px',
                                backgroundColor: '#f8f9fa',
                            } }
                        >
                            <p>
                                By clicking on Register and Submit Assignment button, you will leave the Absa RtW
                                app.
                                Any personal information provided by you to Enterprises University of Pretoria will
                                be
                                subjected to their Personal Information and Data storage and processing
                                requirements.
                                You will be required to give consent to Enterprises UP to process your personal data
                                for the purposes of obtaining Certification for Introduction to Project Management.
                            </p>
                        </div>
                    ) }

                    <div className="d-flex w-100 gap-3"
                         style={ { marginTop: '12px' } }>
                        <Button
                            className="w-50 text-center"
                            style={ { whiteSpace: 'nowrap' } }
                            onClick={ () => {
                                const link = document.createElement('a');
                                link.href = '/project_management/assignment/Assignment-Introduction-to-Project-Management.docx';
                                link.download = 'Assignment-Introduction-to-Project-Management.docx';
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                            } }
                        >
                            Download Assignment
                        </Button>
                        <Button
                            className="w-50 text-center"
                            style={ { whiteSpace: 'nowrap' } }
                            onClick={ handleRegisterClick }
                        >
                            Register and Submit Assignment
                        </Button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                { showDisclaimer && (
                        <Button
                            variant="primary"
                            onClick={ () =>
                                window.open('https://forms.gle/ecrcKRRCSnfr1k3n6', '_blank', 'noopener,noreferrer')
                            }
                        >
                            Continue
                        </Button>
                    ) }
                    <Button variant="secondary" onClick={ handleModalClose }>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        }
    </>;
};

export default ModuleViewPage;
