import { FC } from "react"
import { Badge } from "react-bootstrap"
import { BsClock } from "react-icons/bs"
import { LearningModule, LearningModuleSummary } from "../_models/types"
import { history } from '../_helpers/history';
import { scormModuleNames } from '../_helpers/scormStorage';
import { formatModuleName } from "../_helpers/utils";
import {track as amplitudeTrack} from '@amplitude/analytics-browser';

interface IProps {
    module: LearningModule
    moduleSummary: LearningModuleSummary
    chaptersInProgress: number
    chaptersCompleted: number
}
const handleModuleClick = (moduleName: string) => {
    const eventProperties = {
        moduleName: moduleName,
    };
    amplitudeTrack("Module Clicked", eventProperties)
    history.navigate(`my-learning/${moduleName}`)
};
const ModuleComponent: FC<IProps> = ({ module, chaptersInProgress, chaptersCompleted, moduleSummary }) => {
    const configuration: any = {
        "money": {
            points: ["Spending and saving wisely", "A step-by-step guide to creating your own budget", "Understanding and using credit to your advantage"],
            time: 50
        },
        "entrepreneurial": {
            points: ["Considering self-employment", "Key things new business owners should know about the marketplace", "Setting up your business"],
            time: 60
        },
        "work": {
            points: ["The world of work: How to get in and stay in!", "Creating a CV you can be proud of", "Identifying, pursuing and getting what you want"],
            time: 85
        },
        "people": {
            points: ["Developing effective people skills", "Developing effective communication skills", "Understanding emotional intelligence"],
            time: 40
        },
        "blockchain": {
            points: ["Blockchain and bitcoin (and other forms of cryptocurrency) are hyped and hot technology topics. They are elements within new emerging systems that hold much promise and might disrupt existing business models."],
            time: 35
        },
        "computational_thinking": {
            points: ["This module will give you a basic introduction to computational thinking, the skill that underlies an understanding of using logic to solve problems in the non-computing environments of everyday life and the workplace, as well as on computers."],
            time: 15
        },
        "the_gig_economy": {
            points: ["Markets in a gig economy rely heavily on independent contractors and temp staff over permanent employees. This module will explore the current gig economy and the impacts thereof on businesses and workers."],
            time: 25
        },
        "introduction_to_digital_literacy": {
            points: ["As more and more of the world move online for work, education and socialisation, it is vital for your security and career growth to have strong digital literacy."],
            time: 20
        },
        "tools_for_stress_management": {
            points: ["Stress factors in your work and home life can easily result in you becoming overwhelmed, this module will help give you the tools needed to mitigate and manage your sources of stress and stress levels."],
            time: 35
        },
        "embracing_change": {
            points: ["In the modern world, the technologies and the ways of work you are exposed to will be constantly changing, it is important that you are able to embrace this change and adapt. This module will help you build the skillset needed to do so."],
            time: 45
        },
        "time_management": {
            points: ["This module will help you to manage time as a resource optimally in the workplace and in your daily life, including how to use time logs and exploring a holistic approach built on SMART goals that will enable you to enjoy success at work as well as quality of life."],
            time: 50
        },
        "creative_problem_solving": {
            points: ["This module will describe the skills and steps required to find solutions to problems and challenges that you might encounter in the workplace or in your career, and provide you with a system of skills and steps to follow when confronted with a problem."],
            time: 40
        },
        "creative_thinking": {
            points: ["In this module, we will look at the role of creativity and creative thinking in enhancing innovation. The module will embrace the nature, process, and role of creativity towards thinking new and develop new ideas, services and or products."],
            time: 40
        },
        "project_management": {
            points: ["In this module, we will explore the critical role of project management in fostering innovation. The module will delve into the principles, processes, and best practices of project management to ensure the successful development and implementation of new ideas, services, or products."],
            time: 180
        }
    }
    return <><div key={module.name} className="col-12 resources__modules" onClick={() => handleModuleClick(module.name)}>
        <div className="col-12">
            <div className="row">
                <div className="col-12 resources__modules__header">
                    <div className="row">
                        <div className="col-8 resources__modules__header-text">
                            <p>{formatModuleName(module.name)}</p>
                        </div>
                        <div className="col-4 resources__modules__time">
                            <Badge bg="primary">
                                {configuration[module.name]?.time}m <BsClock />
                            </Badge>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="row">
                        <div className="col-12 col-md-8 resources__modules__details">
                            <ul className="row">
                                {
                                    configuration[module.name]?.points.map((point: string, indx: number) => <li key={indx}>{point}</li>)
                                }
                            </ul>
                        </div>
                        <div className="col-12 col-md-4 resources__modules__statistics">
                            {!scormModuleNames.includes(module.name) &&
                                <>
                                    <div className="module-percent w-100">
                                        <p>{Math.round((chaptersCompleted / moduleSummary.total) * 100)}%</p>
                                    </div>
                                    <div className="module-progress w-100">
                                        Progress: {chaptersCompleted} of {moduleSummary.total}
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
}

export default ModuleComponent;
