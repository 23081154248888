import { useContext, useEffect, useState } from 'react';
import { BsDownload } from 'react-icons/bs';
import { ReactComponent as Moneybag } from '../assets/absa_icons/Icon_Moneybag_Rand_SVG_Black.svg';
import { ReactComponent as People } from '../assets/absa_icons/Icon_Group_People_SVG_Black.svg';
import { ReactComponent as Entrepreneur } from '../assets/absa_icons/Icon_Training_and_Development_SVG_Black.svg';
import { ReactComponent as Work } from '../assets/absa_icons/Icon_Office chair_SVG_Black.svg';
import UserAuthStateContext from '../_contexts/userAuth.context';
import { userService } from '../_services';
import useAPI from '../_hooks/useAPI';
import { UserDetails } from '../_models/types';

type DownloadItem = {
    label: string
    fileName?: string
    adminOnly?: boolean
    customHandler?: boolean
}

type ResourceSection = {
    title: string
    className: string
    icon: any
    bulletPoints: string[]
    downloads: DownloadItem[]
}

const resourcesData: ResourceSection[] = [
    {
        title: 'Money skills',
        className: 'money',
        icon: <Moneybag className="icon" />,
        bulletPoints: [
            'Spending and saving wisely',
            'A step-by-step guide to creating your own budget',
            'Understanding and using credit to your advantage',
        ],
        downloads: [
            {
                label: 'Facilitator Guide',
                fileName: '67508 RTW FACGUIDE ONLINE MONEY SKILLS.pdf',
                adminOnly: true,
            },
            { label: 'Topic 1', fileName: '66540 RTW MOD3 Topic1 Final.pdf' },
            { label: 'Topic 2', fileName: '66541 RTW MOD3 Topic2 Final.pdf' },
            { label: 'Topic 3', fileName: '66542 RTW MOD3 Topic3 Final.pdf' },
            {
                label: 'Money Assessment',
                fileName: 'RTW Assessment MOD3 money.pdf',
                adminOnly: true,
            },
            {
                label: 'Money Answer',
                fileName: 'RTW ANSWER MOD3 money_R1.pdf',
                adminOnly: true,
            },
            {
                label: 'Certificate',
                fileName: 'Certificate Money Skills.pdf',
                adminOnly: true,
            },
        ],
    },
    {
        title: 'Entrepreneurial skills',
        className: 'entrepreneurial',
        icon: <Entrepreneur className="icon" />,
        bulletPoints: [
            'Considering self-employment',
            'Key things new business owners should know about the market',
            'Setting up your business',
        ],
        downloads: [
            {
                label: 'Facilitator Guide',
                fileName: 'FGUIDE_ENTREPRENEURIAL.pdf',
                adminOnly: true,
            },
            { label: 'Topic 1', fileName: '66544 RTW MOD4 Topic1 Final.pdf' },
            { label: 'Topic 2', fileName: '66545 RTW MOD4 Topic2 Final.pdf' },
            { label: 'Topic 3', fileName: '66546 RTW MOD4 Topic3 Final.pdf' },
            {
                label: 'Entrepreneurial Assessment',
                fileName: 'RTW Assessment MOD4 entrepreneurial.pdf',
                adminOnly: true,
            },
            {
                label: 'Entrepreneurial Answer',
                fileName: 'RTW ANSWER MOD4 entrpreneurial_R1.pdf',
                adminOnly: true,
            },
            { label: 'Certificate', fileName: 'Certificate Entrepreneurial Skills.pdf', adminOnly: true },
        ],
    },
    {
        title: 'Work skills',
        className: 'work',
        icon: <Work className="icon" />,
        bulletPoints: [
            'The world of work: How to get in and stay in!',
            'Creating a CV you can be proud of',
            'Identifying, pursuing and getting what you want',
        ],
        downloads: [
            {
                label: 'Facilitator Guide',
                fileName: '66534 F_Guide MOD1 work skills FINAL.pdf',
                adminOnly: true,
            },
            { label: 'Topic 1', fileName: '66457 RTW MOD1 Topic1 FINAL.pdf' },
            { label: 'Topic 2', fileName: '66458 RTW MOD1 Topic2 FINAL.pdf' },
            { label: 'Topic 3', fileName: '66518 RTW MOD1 Topic3 FINAL.pdf' },
            { label: 'Topic 4', fileName: '66519 RTW MOD1 Topic4 FINAL.pdf' },
            {
                label: 'Work Assessment',
                fileName: 'RTW Assessment MOD1 work.pdf',
                adminOnly: true,
            },
            { label: 'Work Answer', fileName: 'RTW ANSWER MOD1 work_R1.pdf', adminOnly: true },
            { label: 'Certificate', fileName: 'Certificate Work Skills.pdf', adminOnly: true },
        ],
    },
    {
        title: 'People skills',
        className: 'people',
        icon: <People className="icon" />,
        bulletPoints: [
            'Developing effective people skills',
            'Developing effective communication skills',
            'Understanding emotional intelligence',
        ],
        downloads: [
            {
                label: 'Facilitator Guide',
                fileName: '66538 FACGuideMOD2 People skills FINAL.pdf',
                adminOnly: true,
            },
            { label: 'Topic 1', fileName: '66524 RTW MOD2 Topic1 Final.pdf' },
            { label: 'Topic 2', fileName: '66527 RTW MOD2 Topic2 Final.pdf' },
            { label: 'Topic 3', fileName: '66523 RTW MOD2 Topic3 Final.pdf' },
            {
                label: 'People Assessment',
                fileName: 'RTW Assessment MOD2 people.pdf',
                adminOnly: true,
            },
            { label: 'People Answer', fileName: 'RTW ANSWER MOD2 people_R1.pdf', adminOnly: true },
            { label: 'Certificate', fileName: 'Certificate People Skills.pdf', adminOnly: true },
        ],
    },
    {
        title: 'Project Management',
        className: 'project-management',
        icon: <Entrepreneur className="icon" />,
        bulletPoints: [
            'Planning and scheduling',
            'Resource allocation and management',
            'Risk management and mitigation',
        ],
        downloads: [
            {
                label: 'Project Management',
                customHandler: true,
            },
        ],
    },
];

export default function ResourcesPage() {
    const { UserAuthState } = useContext(UserAuthStateContext);
    const [user, setUser] = useState<UserDetails | null>(null);
    const fetchWrapper = useAPI();

    useEffect(() => {
        const getUser = async () => {
            if (UserAuthState) {
                const userDetails = await userService.getUserInformation(UserAuthState.user_id, fetchWrapper);
                setUser({ ...userDetails });
            }
        };
        getUser();
    }, [UserAuthState, fetchWrapper]);

    // Download assets from ../assets/module_guides/
    const downloadAsset = async (fileName: string) => {
        const moduleRef = await import(`../assets/module_guides/${ fileName }`);
        const fileURL = moduleRef.default;
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = fileName;
        link.click();
    };

    // Custom PDF download from /public folder
    const downloadCombinedPDF = () => {
        const pdfURL = "/project_management/combined_modules/Combined-Modules-Introduction-to-Project-Management.pdf";
        const link = document.createElement("a");
        link.href = pdfURL;
        link.download = "Combined-Modules-Introduction-to-Project-Management.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="col-12 about-us">
            <div className="row">
                <div className="hero-image">
                    <img src="/images/J_FAQ.jpg" alt="" className="w-100 img-fluid"/>
                    <div className="h-100 w-100 hero-image__title">
                        <div className="container text-center">
                            <h3>Resources</h3>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-10 offset-lg-1 myLearning">
                    <div className="row myLearning__body">
                        { user && (
                            <>
                                { resourcesData.map((section, idx) => (
                                    <div key={ idx } className="col-12 col-lg-6">
                                        <div
                                            className={ `col-12 myLearning__module myLearning__module--${ section.className }` }>
                                            <div className="row">
                                                {/* Header row */ }
                                                <div className="col-12 myLearning__module__header">
                                                    <div className="row">
                                                        <div className="col-10 myLearning__module__header-text">
                                                            <p>{ section.title }</p>
                                                        </div>
                                                        <div className="col-2 myLearning__module__icon">
                                                            <div className="icon-container">
                                                                { section.icon }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Body row */ }
                                                <div className="col-12">
                                                    <div className="row">
                                                        {/* Bullet points */ }
                                                        <div className="col-12 col-lg-7 myLearning__module__details">
                                                            <ul className="row">
                                                                { section.bulletPoints.map((bp, i) => (
                                                                    <li key={ i }>{ bp }</li>
                                                                )) }
                                                            </ul>
                                                        </div>

                                                        {/* Download items */ }
                                                        <div className="col-12 col-lg-5 myLearning__module__downloads">
                                                            { section.downloads.map((dl, j) => {
                                                                // If item is adminOnly, show only if user.is_admin
                                                                if (dl.adminOnly && !user.is_admin) {
                                                                    return null;
                                                                }

                                                                // Handler for onClick
                                                                const handleClick = () => {
                                                                    if (dl.customHandler) {
                                                                        downloadCombinedPDF();
                                                                    } else if (dl.fileName) {
                                                                        downloadAsset(dl.fileName);
                                                                    }
                                                                };

                                                                return (
                                                                    <div
                                                                        key={ j }
                                                                        className="col-12 download-item"
                                                                        onClick={ handleClick }
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                            userSelect: "none",
                                                                        }}
                                                                    >
                                                                        <div className="row">
                                                                            <div className="col-3 download-item__icon">
                                                                                <BsDownload/>
                                                                            </div>
                                                                            <div className="col-9 download-item__text">
                                                                                <p>{ dl.label }</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }) }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )) }
                            </>
                        ) }
                    </div>
                </div>
            </div>
        </div>
    );
}
