import { FC, useEffect, useState } from "react";
import { Button, Form, ProgressBar, Spinner } from "react-bootstrap"
import { formatModuleName } from "../_helpers/utils";
import useAPI from "../_hooks/useAPI";
import { OptionTypes } from "../_models/types";
import { quizService } from "../_services";
import { Rating } from 'react-simple-star-rating'
import AlertWrapper from "../_helpers/alert-wrapper";

interface IProps {
    moduleNumber: number;
    moduleName: string;
    quizResult: () => void
}
const AssessmentComponent: FC<IProps> = ({ moduleNumber, moduleName, quizResult: refreshQuizResult }) => {
    const [quizProgress, setQuizProgress] = useState<{ progress: number, result: number }>();
    const [moduleQuiz, setModuleQuiz] = useState<{ questions: any }>();
    const [loadedQuiz, setLoadedQuiz] = useState(-1);
    const [Loading, setLoading] = useState(false);
    const [RatedCourse, setRatedCourse] = useState(false);
    const [selectedAnswer, setSeletedAnswer] = useState(-1)
    const [activeOption, setActiveOption] = useState(-1)
    const [quizResult, setQuizResult] = useState<{ progress: number, result: number } | null>(null)
    const fetchWrapper = useAPI();

    useEffect(() => {
        const resetQuizProgress = async () => await quizService.resetQuizProgress(fetchWrapper, moduleName);
        const getQuizStatus = async () => setQuizProgress(await quizService.getQuizStatus(fetchWrapper, moduleName));
        const getQuizByModule = async () => setModuleQuiz(await quizService.getQuizByModule(fetchWrapper, moduleName));
        resetQuizProgress()
        getQuizStatus()
        getQuizByModule()
    }, [])

    useEffect(() => {
        quizProgress && setLoadedQuiz(0);
    }, [moduleQuiz, quizProgress]);

    const getQuizResult = async () => {
        setQuizResult(await quizService.getQuizResultMapped(fetchWrapper, moduleName));
    }

    useEffect(() => {
        if (!Loading && (loadedQuiz + 1 > moduleQuiz?.questions.length)) {
            getQuizResult();
        }
    }, [Loading]);

    const getProgressBar = (value: number) => {
        return <ProgressBar now={value} label={`${value}%`} />;
    }

    const submitAnswer = async () => {
        setLoading(true);
        if (selectedAnswer !== -1) {
            try {
                await quizService.answerQuestion(fetchWrapper, { option: Object.values(OptionTypes)[selectedAnswer] }, moduleName, loadedQuiz + 1);
                refreshQuizResult()
                setLoadedQuiz(loadedQuiz + 1);
                setLoading(false);
                setSeletedAnswer(-1);
            } catch (error) {
                setLoading(false);
            }
        }
    }

    const selectAnswer = (e: any, optionId: number) => {
        setActiveOption(optionId);
        setSeletedAnswer(Number(e.target.value))
    }

    const restart = () => {
        setLoadedQuiz(0);
        setQuizResult(null)
    }

    const rateCourse = async (rate: number) => {
        try {
            await quizService.rateQuiz(fetchWrapper, { rating: rate }, moduleName);
            setRatedCourse(true);
        } catch (error) {

        }
    }

    return <>
        {
            moduleQuiz && loadedQuiz !== -1 ?
                <div className="col-12 assessment">
                    {
                        moduleQuiz.questions[loadedQuiz] ?
                            <div className="w-100 py-2">
                                <p className="assessment__header-text-progress">{`${formatModuleName(moduleName)}`} Assessment</p>
                                <h3 className="mt-4 assessment__question-number">Question {loadedQuiz + 1} of {moduleQuiz.questions.length}</h3>
                                <p className="assessment__instructions">Choose the correct answer to the following statement by selecting it and clicking on the submit button.</p>
                                <h3 className="assessment__question">
                                    {
                                        `${moduleQuiz.questions[loadedQuiz].text}`
                                    }
                                </h3>
                                <div className="w-100 mb-3">
                                    {
                                        moduleQuiz.questions[loadedQuiz].options.map((opt: any, i: number) =>
                                            <Form.Check
                                                key={i}
                                                checked={activeOption === opt.id}
                                                inline
                                                label={opt.text}
                                                type="radio"
                                                id={opt.id}
                                                value={i}
                                                name="group1"
                                                className="d-block assessment__answer"
                                                onChange={(e) => selectAnswer(e, opt.id)}
                                            />
                                        )
                                    }
                                </div>
                                <div className="w-100">
                                    <Button variant="primary" size="lg" onClick={(submitAnswer)} disabled={Loading || (selectedAnswer === -1)}>
                                        Submit
                                    </Button>
                                </div>
                            </div> :
                            <div className="w-100 assessment__quiz-result">
                                <div className="assessment__quiz-result__head">
                                    <p className="assessment__header-text-progress">Your final score is</p>
                                    <p className="assessment__score">{quizResult?.result}%</p>
                                </div>
                                {getProgressBar(quizResult?.result as number)}
                                <>
                                    {
                                        (quizResult?.result as number) < 80 ?
                                            <p className="assessment__instructions mt-2">
                                                The pass mark is 80%. Click <span className="link" onClick={() => restart()}>Here</span> to try the assessment again.
                                            </p> :
                                            <div className="assessment__quiz-result__rate">
                                                <hr />
                                                {RatedCourse && <AlertWrapper variant="success" message="Successfully rated course." closeMsg={() => setRatedCourse(false)} />}
                                                <h3>Rate The Course</h3>
                                                <Rating
                                                    onClick={rateCourse}
                                                    fillColor="#fa551e"
                                                />
                                            </div>
                                    }
                                </>
                            </div>

                    }
                </div> : <Spinner animation="border" size="sm" />
        }
    </>
}

export default AssessmentComponent;
